import styled from 'styled-components';
import { Nav } from 'react-bootstrap';

export const HeaderContainer = styled.div`
  background-color  : rgba(255, 255, 255, 0.75);
  box-shadow        : 0 4px 4px rgba(0, 0, 0, 0.1);
  position          : sticky;
  top               : 10;
  z-index           : 99;
  display           : block;
  justify-content   : space-between;
  align-items       : center;
  padding           : 10px 0;
`;

export const Logo = styled.img`
  height  : 25px;
  width   : 25px;
`;

export const NavLink = styled(Nav.Link)`
  color       : #222;
  font-weight : 600;
  margin-left : 20px;

  &:hover {
    color : #007bff;
  }
`;

export const NavContainer = styled.div`
  display     : flex;
  align-items : center;
`;
