import React, { useState }  from 'react';
import { useNavigate }      from 'react-router';
import Button               from '../forms/Button';
import FormInput            from '../forms/FormInput';
import { FormWrap, Form }   from './index.elements';
import AuthWrapper          from '../AuthWrapper';
import vCard                from './vCard';
import FormTextBox          from '../forms/FormTextBox';

const Contact = props => {
  const [name,    setName]    = useState('');
  const [email,   setEmail]   = useState('');
  const [message, setMessage] = useState('');
  const navigate              = useNavigate();

  const handleFormSubmit = event => {
    event.preventDefault();

    
    // Your code for sending the contact form data to the server
  };

  const handleDownload = () => {
    const element     = document.createElement('a');
    const file        = new Blob([vCard], {type: "text/vcard;charset=utf-8"});
    element.href      = URL.createObjectURL(file);
    element.download  = 'contacts.vcf';
    document.body.appendChild(element);
    element.click();
  };

  const configAuthWrapper = {
    headline      : 'Contact Us',
    destination1  : 'Go back to home',
    href1         : '/'
  };

  return (
    <AuthWrapper {...configAuthWrapper}>
      <FormWrap>
        <Form onSubmit={handleFormSubmit}>
          <FormInput
            type        ="text"
            name        ="name"
            value       ={name}
            placeholder ="Name"
            handleChange={e => setName(e.target.value)}
          />
          <FormInput
            type        ="text"
            name        ="email"
            value       ={email}
            placeholder ="Email"
            handleChange={e => setEmail(e.target.value)}
          />
          <FormTextBox
            type        ="text"
            name        ="message"
            value       ={message}
            placeholder ="Message"
            handleChange={e => setMessage(e.target.value)}
          />
          <Button type    ="submit">Submit</Button>
          <Button onClick ={handleDownload}>Download Contact</Button>
        </Form>
      </FormWrap>
    </AuthWrapper>
  );
};

export default Contact;
