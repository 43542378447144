import './default.css';
import React, { useEffect }     from  'react';
import { useDispatch }          from  'react-redux';
import { 
  Route,
  Routes }                      from  'react-router-dom';

import { checkUserSession }     from  './redux/User/user.actions';

// // HOC
import WithAuth                 from  './hoc/withAuth';
import WithAdminAuth            from  './hoc/withAdminAuth';

// // layouts
import UnauthenticatedLayout  from './layouts/UnauthenticatedLayout';
import LimitedLayout          from './layouts/LimitedLayout';
// import AuthenticatedLayout    from './layouts/AuthenticatedLayout';

// // pages
import HomePage               from './pages/Homepage';
import SignUpPage             from './pages/Signup';
import LoginPage              from './pages/Login';
import PageNotFoundPage       from './pages/PageNotReadyPage';
import FAQPage                from './pages/FAQPage';
import DetachmentsPage from './pages/DetachmentsPage';
// import ProfilePage from './pages/Profile';

  const App = props => {
    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(checkUserSession());
  
    }, []);
  
  
      return (
        <div className="App">
          {/* <AdminToolbar /> */}
          <Routes>
            <Route path='/'                   element={
            // <HomepageLayout >
            <UnauthenticatedLayout>
              <HomePage /> 
            </UnauthenticatedLayout>
            // </HomepageLayout>
          } />
          <Route path='/signup'              element={
            // <HomepageLayout >
              <SignUpPage />
            // </HomepageLayout>
          } />
          <Route path='/signin'               element={
            // <HomepageLayout >
            <LoginPage />
            // </HomepageLayout>
          } />
          <Route path='/faq'                 element={
            <LimitedLayout label="Home"           destination="/">
              <FAQPage />
            </LimitedLayout>
          } />
          <Route path='/detachments'                 element={
            <UnauthenticatedLayout>
              <DetachmentsPage />
            </UnauthenticatedLayout>
          } />
          <Route path='*'                     element={
            <LimitedLayout label="Home" destination="/"  >
              <PageNotFoundPage />
            </LimitedLayout>
          } />
          </Routes>

        </div>
      );
    };


export default App;
