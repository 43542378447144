import React, { useState }  from 'react';
// import NavbarLimited     from '../components/NavbarLimited';
import Navbar               from '../components/Navbar';
import Footer               from '../components/Footer';
import Sidebar              from '../components/Sidebar';

const LimitedLayout = props => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} {...props} />
        {props.children}
        <Footer />
    </>
  )
}

export default LimitedLayout