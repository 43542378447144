import React, {
  useState,
  useEffect }                       from 'react';

import {
  useDispatch,
  useSelector,
  connect }                         from 'react-redux';

import { IconContext }              from 'react-icons/lib';
import { animateScroll as scroll }  from 'react-scroll';
import logo                         from './../../assets/images/MCL_Logo_BWRG.svg';

import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavLogoImage,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavGradient,
  Hamburger }                       from './elements';
import { signOutUserStart }         from '../../redux/User/user.actions';

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser
});

const Navbar = ({ toggle }) => {
  const dispatch                  = useDispatch();
  const {currentUser}             = useSelector(mapStateToProps);
  const [click, setClick]         = useState(false);
  const signOut                   = () => {
    dispatch(signOutUserStart());
  };

  const handleClick               = () => setClick(!click);
  const closeMobileMenu           = () => setClick(false);

  const [scrollnav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollnav={ scrollnav ? 1 : 0 }>
          <NavGradient scrollnav={ scrollnav ? 1 : 0 }>
          <NavbarContainer>
            <NavLogo onClick={toggleHome} to='/'>
              <NavLogoImage src={logo} />
            </NavLogo>
            <MobileIcon onClick={toggle} scrollnav={ scrollnav ? 1 : 0 }>
              <Hamburger scrollnav={ scrollnav ? 1 : 0 } color='#1993356' />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to='about'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollnav={ scrollnav ? 1 : 0 }
                >
                  About
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='discover'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollnav={ scrollnav ? 1 : 0 }
                >
                  Discover
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='services'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollnav={ scrollnav ? 1 : 0 }
                >
                  Services
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='signup'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  scrollnav={ scrollnav ? 1 : 0 }
                >
                  Sign Up
                </NavLinks>
              </NavItem>
            </NavMenu>
            {currentUser && (
              <>
                <NavBtn>
                  <NavBtnLink to='/dashboard'> Dashboard </NavBtnLink>
                </NavBtn>
              </>
            )}

            {!currentUser && (
              <>
                <NavBtn>
                  <NavBtnLink to='/dashboard'> Sign In </NavBtnLink>
                </NavBtn>
              </>
            )}
            
          </NavbarContainer>
          </NavGradient>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default connect(mapStateToProps, null)(Navbar);


// import React, {
//   useState,
//   useEffect }                       from 'react';

// import {
//   useDispatch,
//   useSelector,
//   connect }                         from 'react-redux';

// import { IconContext }              from 'react-icons/lib';
// import { animateScroll as scroll }  from 'react-scroll';
// import logo                         from '../../assets/images/TapToNetworkLogoOnlyTransparent.svg';


// import {
//   MobileIcon,
//   Nav,
//   NavbarContainer,
//   NavItem,
//   NavLinks,
//   NavLogo,
//   NavLogoImage,
//   NavMenu,
//   NavBtn,
//   NavBtnLink,
//   NavGradient,
//   Hamburger }                       from './elements';
// import { signOutUserStart }         from '../../redux/User/user.actions';

// const mapStateToProps = ({ user }) => ({
//   currentUser: user.currentUser
// });

// const Navbar = ({ toggle }) => {
//   const dispatch                  = useDispatch();
//   const {currentUser}             = useSelector(mapStateToProps);
//   const [click, setClick]         = useState(false);
//   const signOut                   = () => {
//     dispatch(signOutUserStart());
//   };

//   const handleClick               = () => setClick(!click);
//   const closeMobileMenu           = () => setClick(false);

//   const [scrollnav, setScrollNav] = useState(false);

//   const changeNav = () => {
//     if (window.scrollY >= 80) {
//       setScrollNav(true);
//     } else {
//       setScrollNav(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', changeNav);
//   }, []);

//   const toggleHome = () => {
//     scroll.scrollToTop();
//   };

//   return (
//     <>
//       <IconContext.Provider value={{ color: '#fff' }}>
//         <Nav scrollnav={ scrollnav ? 1 : 0 }>
//           <NavGradient scrollnav={ scrollnav ? 1 : 0 }>
//           <NavbarContainer>
//             <NavLogo onClick={toggleHome} to='/'>
//               <NavLogoImage src={logo} />
//             </NavLogo>
//             <MobileIcon onClick={toggle} scrollnav={ scrollnav ? 1 : 0 }>
//               <Hamburger scrollnav={ scrollnav ? 1 : 0 } color='#1993356' />
//             </MobileIcon>
//             <NavMenu>
//               <NavItem>
//                 <NavLinks
//                   to='about'
//                   smooth={true}
//                   duration={500}
//                   spy={true}
//                   exact='true'
//                   offset={-80}
//                   scrollnav={ scrollnav ? 1 : 0 }
//                 >
//                   About
//                 </NavLinks>
//               </NavItem>
//               <NavItem>
//                 <NavLinks
//                   to='discover'
//                   smooth={true}
//                   duration={500}
//                   spy={true}
//                   exact='true'
//                   offset={-80}
//                   scrollnav={ scrollnav ? 1 : 0 }
//                 >
//                   Discover
//                 </NavLinks>
//               </NavItem>
//               <NavItem>
//                 <NavLinks
//                   to='services'
//                   smooth={true}
//                   duration={500}
//                   spy={true}
//                   exact='true'
//                   offset={-80}
//                   scrollnav={ scrollnav ? 1 : 0 }
//                 >
//                   Services
//                 </NavLinks>
//               </NavItem>
//               <NavItem>
//                 <NavLinks
//                   to='signup'
//                   smooth={true}
//                   duration={500}
//                   spy={true}
//                   exact='true'
//                   offset={-80}
//                   scrollnav={ scrollnav ? 1 : 0 }
//                 >
//                   Sign Up
//                 </NavLinks>
//               </NavItem>
//             </NavMenu>
//             {currentUser && (
//               <>
//                 <NavBtn>
//                   <NavBtnLink to='/dashboard'> Dashboard </NavBtnLink>
//                 </NavBtn>
//               </>
//             )}

//             {!currentUser && (
//               <>
//                 <NavBtn>
//                   <NavBtnLink to='/dashboard'> Sign In </NavBtnLink>
//                 </NavBtn>
//               </>
//             )}
            
//           </NavbarContainer>
//           </NavGradient>
//         </Nav>
//       </IconContext.Provider>
//     </>
//   );
// };

// export default connect(mapStateToProps, null)(Navbar);
