import React        from 'react';
import Contact      from '../../components/ContactUs';
import PageNotReady from '../../components/PageNotReady';
function PageNotFoundPage() {

  return (
    <>
      <PageNotReady
      headline    ="Thank you for your interest!"
      description ="We are excited that you are interested in our service. We are almost ready to launch this page! Please check back soon or contact us if you have more questions."
      actionText  ="Stay Connected" />
      <Contact/>
    </>
  );
}

export default PageNotFoundPage;
