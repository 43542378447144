import React from 'react';
import Icon1 from '../../assets/images/ecommerce.svg';
import Icon2 from '../../assets/images/profile.svg';
import Icon3 from '../../assets/images/marketing_tools.svg';
import Icon4 from '../../assets/images/real_time_collaboration.svg';
import Icon5 from '../../assets/images/feedback.svg';

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './services.elements.js';

const Services = () => {
  return (
    <ServicesContainer id='services'>
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Smart Member Card Solutions</ServicesH2>
          <ServicesP>
          NFC-enabled business cards for members to help you stand out from the crowd and share League resources
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Profile Hosting & Management</ServicesH2>
          <ServicesP>
          Say goodbye to outdated paper member applications and documents. With online profiles, you can access member information onlin in real time to make updates or locate information
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Digital Meeting Minutes</ServicesH2>
          <ServicesP>
          Record digital meeting minutes and upload to the cloud to access at any point in real time.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>A Library of Resources</ServicesH2>
          <ServicesP>
          Provide a library of resources at each Marine's fingertips.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
