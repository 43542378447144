import detachmentTypes from './detachments.types';

// Fetching Detachments
export const fetchDetachmentsStart = () => ({
    type: detachmentTypes.FETCH_DETACHMENTS_START
});

export const fetchDetachmentsSuccess = detachments => ({
    type: detachmentTypes.FETCH_DETACHMENTS_SUCCESS,
    payload: detachments
});

export const fetchDetachmentsError = err => ({
    type: detachmentTypes.FETCH_DETACHMENTS_ERROR,
    payload: err
});

// Adding a Detachment
export const addDetachmentStart = detachment => ({
    type: detachmentTypes.ADD_DETACHMENT_START,
    payload: detachment
});

export const addDetachmentSuccess = detachment => ({
    type: detachmentTypes.ADD_DETACHMENT_SUCCESS,
    payload: detachment
});

export const addDetachmentError = err => ({
    type: detachmentTypes.ADD_DETACHMENT_ERROR,
    payload: err
});

// Updating a Detachment
export const updateDetachmentStart = (id, updatedDetachment) => ({
    type: detachmentTypes.UPDATE_DETACHMENT_START,
    payload: { id, updatedDetachment }
});

export const updateDetachmentSuccess = (id, updatedDetachment) => ({
    type: detachmentTypes.UPDATE_DETACHMENT_SUCCESS,
    payload: { id, updatedDetachment }
});

export const updateDetachmentError = err => ({
    type: detachmentTypes.UPDATE_DETACHMENT_ERROR,
    payload: err
});

// Deleting a Detachment
export const deleteDetachmentStart = id => ({
    type: detachmentTypes.DELETE_DETACHMENT_START,
    payload: id
});

export const deleteDetachmentSuccess = id => ({
    type: detachmentTypes.DELETE_DETACHMENT_SUCCESS,
    payload: id
});

export const deleteDetachmentError = err => ({
    type: detachmentTypes.DELETE_DETACHMENT_ERROR,
    payload: err
});
