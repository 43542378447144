const detachmentTypes = {
    FETCH_DETACHMENTS_START   : 'FETCH_DETACHMENTS_START',
    FETCH_DETACHMENTS_SUCCESS : 'FETCH_DETACHMENTS_SUCCESS',
    FETCH_DETACHMENTS_ERROR   : 'FETCH_DETACHMENTS_ERROR',
    ADD_DETACHMENT_START      : 'ADD_DETACHMENT_START',
    ADD_DETACHMENT_SUCCESS    : 'ADD_DETACHMENT_SUCCESS',
    ADD_DETACHMENT_ERROR      : 'ADD_DETACHMENT_ERROR',
    UPDATE_DETACHMENT_START   : 'UPDATE_DETACHMENT_START',
    UPDATE_DETACHMENT_SUCCESS : 'UPDATE_DETACHMENT_SUCCESS',
    UPDATE_DETACHMENT_ERROR   : 'UPDATE_DETACHMENT_ERROR',
    DELETE_DETACHMENT_START   : 'DELETE_DETACHMENT_START',
    DELETE_DETACHMENT_SUCCESS : 'DELETE_DETACHMENT_SUCCESS',
    DELETE_DETACHMENT_ERROR   : 'DELETE_DETACHMENT_ERROR'
};

export default detachmentTypes;
