export const heroObj1 = {
  id            : 'hero',
  destination   : 'about',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Empowerment at Your Fingertips',
  headline      : 'MCL Central - Empower Your Marine Corps League Experience',
  description   : 'Streamline, Connect, and Engage with Every Aspect of the Marine Corps League',
  buttonLabel   : 'Explore MCL Central',
  imgStart      : true,
  img           : require('../../assets/images/MCL_Logo_BWRG.svg').default,
  alt           : 'GlobePins',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const homeObj1 = {
  id            : 'about',
  destination   : 'discover',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'About MCL Central',
  headline      : 'Your Digital Gateway to the Marine Corps League',
  description   : 'MCL Central is a comprehensive app designed to bring the Marine Corps League\'s values, traditions, and camaraderie into the digital age. Enhance your involvement with efficient tools for communication, administration, and community engagement, all tailored to the unique needs of the MCL community.',
  buttonLabel   : 'Next: Learn More',
  imgStart      : true,
  img           : require('../../assets/images/digital_age.svg').default,
  alt           : 'Digital',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const homeObj2 = {
  id            : 'discover',
  destination   : 'discover1',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Unified Communication',
  headline      : 'Unified Communication Hub',
  description   : 'Discover a seamless way to stay connected. From encrypted messaging to real-time alerts, ensure you\'re always in the loop.',
  buttonLabel   : 'Next: Administrative Efficiency',
  imgStart      : false,
  img           : require('../../assets/images/cloud_sync.svg').default,
  alt           : 'GlobePins',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const homeObj3 = {
  id            : 'discover1',
  destination   : 'discover2',
  isScroll      : true,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Administrative Tools',
  headline      : 'Simplify Administration',
  description   : 'Automate tasks, manage documents, and track member activities with ease. Focus on what matters most.',
  buttonLabel   : 'Next: Community and Support',
  imgStart      : true,
  img           : require('../../assets/images/lead_generation.svg').default,
  alt           : 'Accounts',
  dark          : true,
  primary       : true,
  darkText      : false
};

export const homeObj4 = {
  id            : 'discover2',
  destination   : 'services',
  isScroll      : true,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Community Engagement',
  headline      : 'Foster Community Engagement',
  description   : 'Join discussions, share experiences, and offer support. Build a stronger, more connected community.',
  buttonLabel   : 'Next: Educational Resources',
  imgStart      : false,
  img           : require('../../assets/images/team.svg').default,
  alt           : 'Accounts',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const homeObj5 = {
  id            : 'signup',
  destination   : '/signup',
  isScroll      : false,
  lightBg       : true,
  lightText     : false,
  lightTextDesc : false,
  topLine       : 'Join Us',
  headline      : 'Join the Movement',
  description   : 'Ready to revolutionize your MCL experience? Join MCL Central today and be part of the change.',
  buttonLabel   : 'Sign Up Now',
  imgStart      : false,
  img           : require('../../assets/images/transfer_files.svg').default,
  alt           : 'Papers',
  dark          : false,
  primary       : false,
  darkText      : true
};

export const pageNotReadyObj1 = {
  id            : '404',
  destination   : '/contact',
  isScroll      : false,
  lightBg       : false,
  lightText     : true,
  lightTextDesc : true,
  topLine       : 'Need Our Products?',
  headline      : 'Order products directly from our store!',
  description   : 'From our aesthetic organizer totes, to custom signs and containers, order our products directly from our ecommerce store!',
  buttonLabel   : 'Visit Our Online Store',
  imgStart      : false,
  img           : require('../../assets/images/page_not_found.svg').default,
  alt           : 'Store',
  dark          : true,
  primary       : true,
  darkText      : false
};