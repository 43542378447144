// import Container      from './../../components/Container';
// // import Navbar         from './../../components/Navbar';
// import HeroSection    from './../../components/HeroSection';
// import InfoSection    from './../../components/InfoSection';
// import Services               from './../../components/Services';
// import {
//   homeObj1,
//   homeObj2,
//   homeObj3,
//   homeObj4,
//   homeObj5 } from './../../components/InfoSection/Data';

// const Homepage = () => {
//   return (
//     <Container>
//       {/* <Navbar /> */}
//       <HeroSection/>
//       <InfoSection {...homeObj1} />
//       <InfoSection {...homeObj2} />
//       <InfoSection {...homeObj3} />
//       <Services />
//       <InfoSection {...homeObj4} />
//       <InfoSection {...homeObj5} />
//     </Container>
//   );
// };

// export default Homepage;

import React, { useEffect, useState, useRef } from 'react';
import Container from './../../components/Container';
import HeroSection from './../../components/HeroSection';
import InfoSection from './../../components/InfoSection';
import Services from './../../components/Services';
import {
  homeObj1,
  homeObj2,
  homeObj3,
  homeObj4,
  homeObj5,
} from './../../components/InfoSection/Data';

const Homepage = () => {
  const [audioSrc, setAudioSrc] = useState('../../assets/audio/patriotic.mp3');
  const audioRef = useRef(null);

  // useEffect(() => {
  //   const audioElement = audioRef.current;
  //   audioElement.play();

  //   // Listen for the 'ended' event and then change the audio source.
  //   const handleAudioEnd = () => {
  //     if (audioSrc === '../../assets/audio/patriotic.mp3') {
  //       setAudioSrc('../../assets/audio/Pride And Glory Full Mix.mp3');
  //     } else {
  //       setAudioSrc('../../assets/audio/patriotic.mp3');
  //     }
  //   };

  //   audioElement.addEventListener('ended', handleAudioEnd);

  //   return () => {
  //     // Cleanup
  //     audioElement.removeEventListener('ended', handleAudioEnd);
  //   };
  // }, [audioSrc]);

  return (
    <Container>
      {/* <audio ref={audioRef}>
        <source src={audioSrc} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio> */}
      <HeroSection />
      <InfoSection {...homeObj1} />
      <InfoSection {...homeObj2} />
      <InfoSection {...homeObj3} />
      <InfoSection {...homeObj4} />
      <Services />
      <InfoSection {...homeObj5} />
    </Container>
  );
};

export default Homepage;
