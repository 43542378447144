import React from 'react';
// import Button from '../Button';
import {Button} from '../ButtonElements';
import { heroObj1 } from './../../components/InfoSection/Data';
import { HeroContainer, HeroText, HeroTitle, HeroSubtitle, HeroBGVideo, HeroLogo } from './index.elements';
// import bgvideo from '../../assets/videos/global-network-background.mp4';
import bgvideo from '../../assets/videos/mcl-central-bgvideo.mp4';
import InfoSection from '../InfoSection';
let logo = require('../../assets/images/MCL_Logo_BWRG.svg').default

const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroBGVideo playsInline autoPlay loop muted src={bgvideo} />
      <HeroText>
        {/* <InfoSection {...heroObj1} /> */}
      <HeroLogo src={logo} alt="MCL Logo" style={{ marginRight: "10px" }} />
        <HeroTitle>{heroObj1.topLine}</HeroTitle>
        <HeroSubtitle>{heroObj1.headline}</HeroSubtitle>
        <Button primary={true} big={true} dark={true} fontBig={true} to="about" >
          {heroObj1.buttonLabel}
        </Button>
      </HeroText>
    </HeroContainer>
  );
};

export default HeroSection;



// import React from 'react';
// import Button from '../Button';
// import { HeroContainer, HeroText, HeroTitle, HeroSubtitle } from './index.elements';

// const HeroSection = () => {
//   return (
//     <HeroContainer>
//       <HeroText>
//         <HeroTitle>Connect with ease.</HeroTitle>
//         <HeroSubtitle>Create and share your digital business card.</HeroSubtitle>
//         <Button to="/signup" primary large>
//           Get Started
//         </Button>
//       </HeroText>
//     </HeroContainer>
//   );
// };

// export default HeroSection;
