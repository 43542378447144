import React, { useState } from 'react';

import Video from '../../assets/videos/mcl-central-bgvideo.mp4';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP
} from './elements';

const PageNotReady = ({
  headline,
  description,
  actionText
}) => {

  // const [hover, setHover] = useState(false);

  // const onHover = () => {
  //   setHover(!hover);
  // };

  return (
  <HeroContainer id='home'>
    <HeroBg>
      <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
    </HeroBg>
    <HeroContent>
      <HeroH1>{headline}</HeroH1>
      <HeroP>{description}</HeroP>
    </HeroContent>
  </HeroContainer>

  )
}

export default PageNotReady;
