import React, { useEffect }         from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDetachmentsStart }    from '../../redux/Detachments/detachments.actions';

const DetachmentsPage = () => {
  const dispatch    = useDispatch();
  const detachments = useSelector(state => state.detachments.detachmentsList);
  const loading     = useSelector(state => state.detachments.loading);
  const error       = useSelector(state => state.detachments.error);

  useEffect(() => {
    dispatch(fetchDetachmentsStart());
  }, [dispatch]);

  return (
    <div>
      {loading ? (
        <h2>Loading...</h2>
      ) : error ? (
        <h2>Error: {error}</h2>
      ) : (
        <div>
          <h2>Detachments</h2>
          <ul>
            {detachments.map(detachment => (
              <li key={detachment.id}>{detachment.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DetachmentsPage;
